import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';

import { useConfigContext } from './ConfigContext';

import { UserData } from '../types';
//To show new view name that is added along with respective columns that is selected.

export const UserContext = createContext(null);

export function hasMonthsPassed(
  inputDate: string,
  months: number,
  currentDate: string = null,
): boolean {
  // Ensure the inputDate is a valid Date object
  const inputDateObj = new Date(inputDate);
  let currentDateObj: Date;
  if (!currentDate) currentDateObj = new Date();
  else currentDateObj = new Date(currentDate);
  if (isNaN(inputDateObj.getTime()) || isNaN(currentDateObj.getTime())) {
    return false;
  }

  // Add 6 months to the given date
  const monthsLaterObj = new Date(inputDateObj);
  monthsLaterObj.setMonth(monthsLaterObj.getMonth() + months);
  return currentDateObj >= monthsLaterObj;
}

export const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { push, pathname } = useRouter();

  const [userDetails, setUserDetails] = useState(null); //user_id: '', role_id: "", screen_id: ""
  const [loginDetails, setLoginDetails] = useState<UserData>({});
  const [accessControlDetails, setAccessControlDetails] = useState([]);
  const [subMenuDetails, setSubMenuDetails] = useState({});
  const { configSchema } = useConfigContext();

  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const [acceptDisclaimer, setAcceptDisclaimer] = useState(true);

  useEffect(() => {
    async function fetchSession() {
      const response = await fetch('/api/auth/session', {
        method: 'GET',
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const payload = await response.json();
        setLoginDetails(payload.data);
      } else {
        push('/');
      }
    }
    fetchSession();
  }, []);

  useEffect(() => {
    if (loginDetails?.userId) {
      fetch(
        '/api/userRoleDetails?' +
          new URLSearchParams({
            userAccountId: loginDetails.userId,
            configSchema,
          }),
      )
        .then((res) => res.json())
        .then((userDetails) => {
          const userData = userDetails?.[`${configSchema}_mbd_user_to_role`];
          if (!userData?.length) {
            push('/');
            return;
          }
          const roleIds = userData.map(({ roleId }) => roleId);
          setUserDetails({ ...userData[0], roleIds });
          const lastAcceptDate: string = userData[0]?.[`mbdUserByUserId`]?.[`disclaimerAcceptDate`];
          if (!lastAcceptDate || hasMonthsPassed(lastAcceptDate, 6)) {
            setDisclaimerOpen(true);
            setAcceptDisclaimer(false);
          }
          fetch('/api/screenAccess?' + new URLSearchParams({ userAccountId: loginDetails?.userId }))
            .then((res) => res.json())
            .then((accessControlData) => {
              const accessData = accessControlData[`${configSchema}_mbd_roles_to_screens`];
              if (!accessData.length) {
                push('/404');
                return;
              }

              const access = new Map(
                accessData.map((item) => [item.mbdScreen.screenInternalName, item]),
              );
              const uniqueAccess = [...access.values()];
              setAccessControlDetails(uniqueAccess);
            });
        });
    }
  }, [loginDetails, acceptDisclaimer, pathname]);

  useEffect(() => {
    if (!acceptDisclaimer) {
      push('/');
    }
  }, [acceptDisclaimer]);

  const updateUserDetails = (obj) => {
    setUserDetails((prestate) => ({ ...prestate, ...obj }));
  };

  const updateSubMenuDetails = (subMenu) => {
    setSubMenuDetails((prestate) => ({ ...prestate, ...subMenu }));
  };

  const updateAccessControlDetails = (accessControlData) => {
    setAccessControlDetails(accessControlData);
  };

  const updateLoginDetails = (loginData) => {
    setLoginDetails((prestate) => ({ ...prestate, ...loginData }));
  };

  const updateDisclaimerOpen = (state) => {
    setDisclaimerOpen(state);
  };

  const updateAcceptDisclaimer = (state) => {
    setAcceptDisclaimer(state);
  };

  const value = useMemo(
    () => ({
      userDetails,
      updateUserDetails,
      accessControlDetails,
      updateAccessControlDetails,
      subMenuDetails,
      updateSubMenuDetails,
      loginDetails,
      updateLoginDetails,
      disclaimerOpen,
      updateDisclaimerOpen,
      acceptDisclaimer,
      updateAcceptDisclaimer,
    }),
    [
      updateUserDetails,
      userDetails,
      subMenuDetails,
      accessControlDetails,
      updateAccessControlDetails,
      updateSubMenuDetails,
      loginDetails,
      updateLoginDetails,
      disclaimerOpen,
      updateDisclaimerOpen,
      acceptDisclaimer,
      updateAcceptDisclaimer,
    ],
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
export default UserContextProvider;

export function useUserContextApi() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
