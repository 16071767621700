import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';

i18next.use(HttpApi).init({
  fallbackLng: 'en',
  supportedLngs: ['en', 'de'],
  interpolation: {
    escapeValue: false, // React already does escaping
  },
  //   debug: true,
  //   saveMissing: true,
  //   saveMissingTo: 'fallback',
});

const t = i18next.t;
const changeLanguage = i18next.changeLanguage;

export { t, changeLanguage };
export default i18next;
